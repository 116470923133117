import React from 'react';
import GiphyGallery from './GiphyGallery';
import Blog from './Blog';

const styles = {
  navbar: {
    position: 'fixed',
    top: 0,
    width: '100%',
    backgroundColor: '#fff',
    zIndex: 1000,
    padding: '10px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    textAlign: 'center'
  },
  content: { // ensure content is pushed down beneath navbar
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
};

const Portfolio = () => {
  return (
    <div>
      <div style={styles.content}>
        <GiphyGallery />
        <Blog />
      </div>
    </div>
  );
};

export default Portfolio;
