import React from 'react';
import './Footer.css';
import GiphyIcon from './3.png'; // Update the path accordingly
import LinIcon from './1.png';
import InstaIcon from './2.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-links">
          <a href="https://giphy.com/reutrachellevi"><img src={GiphyIcon} alt="Giphy" style={{ width: '40px', height: 'auto' }}/></a>
          <a href="https://www.linkedin.com/in/reutrachellevi/"><img src={LinIcon} alt="LinkedIn" style={{ width: '40px', height: 'auto' }}/></a>
          <a href="https://www.instagram.com/reutrachellevi/"><img src={InstaIcon} alt="Instagram" style={{ width: '40px', height: 'auto' }}/></a>
          <p>© 2025 Reouth</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
