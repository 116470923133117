import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
  const formRef = useRef();
  const [isHovered, setIsHovered] = useState(false); // Track hover state

  const sendEmail = (event) => {
    event.preventDefault();

    emailjs
      .sendForm(
        'service_hp2mqxd',        // Replace with your EmailJS service ID
        'template_g8z3fpp',       // Replace with your EmailJS template ID
        formRef.current,
        'ZRLnQN0cBAA78ZtAE'       // Replace with your EmailJS public key
      )
      .then(
        (result) => {
          console.log(result.text);
          alert('Message sent successfully!');
        },
        (error) => {
          console.error(error.text);
          alert('An error occurred, please try again.');
        }
      );
  };

  return (
    <div style={styles.pageWrapper}>
      <div style={styles.contactCard}>
        <h2 style={styles.heading}>Contact Me</h2>
        <p style={styles.paragraph}>
          Feel free to reach out to me via the form below or through my social media channels.
        </p>
        
        <form ref={formRef} onSubmit={sendEmail} style={styles.form}>
          {/* Name Field */}
          <div style={styles.formGroup}>
            <label style={styles.label}>Name</label>
            <input 
              type="text"
              name="name"
              style={styles.input}
              placeholder="Your name"
              required
            />
          </div>

          {/* Email in One Row */}
          <div style={styles.row}>
            <div style={{ ...styles.formGroup, marginRight: '5%' }}>
              <label style={styles.label}>Email</label>
              <input
                type="email"
                name="email"
                style={styles.input}
                placeholder="Your email"
                required
              />
            </div>
          </div>

          {/* Message Field */}
          <div style={styles.formGroup}>
            <label style={styles.label}>Message</label>
            <textarea
              name="message"
              rows="5"
              style={{ ...styles.input, height: '100px', resize: 'vertical' }}
              placeholder="Your message"
              required
            ></textarea>
          </div>

          <button
            type="submit"
            style={{
              ...styles.submitButton,
              backgroundColor: isHovered ? 'pink' : '#c83f8c' // Change color on hover
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;

/* Inline style objects for clarity */
const styles = {
  pageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px',
    fontFamily: 'sans-serif',
    fontWeight: 'lighter'
  },
  contactCard: {
    width: '100%',
    maxWidth: '600px',
    backgroundColor: 'white',
    borderRadius: '10px',
    padding: '30px',
    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
    fontFamily: 'sans-serif',
    fontWeight: 'lighter'
  },
  heading: {
    textAlign: 'center',
    marginBottom: '10px',
    fontFamily: 'sans-serif',
    fontWeight: 'lighter'

  },
  paragraph: {
    textAlign: 'center',
    marginBottom: '20px'
  },
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  formGroup: {
    marginBottom: '15px',
    display: 'flex',
    flexDirection: 'column'
  },
  label: {
    marginBottom: '5px'
  },
  input: {
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
    fontSize: '14px'
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  submitButton: {
    padding: '12px 20px',
    backgroundColor: 'black',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    fontSize: '16px',
    cursor: 'pointer',
    alignSelf: 'center',
    marginTop: '10px'
  }
};
