import React from 'react';
import './About.css';
import Illustration from './side.png'; // Make sure to import your illustration image

const About = () => {
  return (
    <div className="about">
      <div className="container">
        <div className="content">
          <div className="image-section">
            <img src={Illustration} alt="Illustration" />
          </div>
          <div style={{fontFamily: "sans-serif", fontWeight: "lighter"}} className="text-section">
            <h2 style={{fontFamily: "sans-serif", fontWeight: "lighter"}}>About Me</h2>
            <p>Welcome to my personal corner of the internet! I'm Reouth, a passionate creator with a deep love for technology and visual arts. With a background in Computer Science, I've dedicated my career to bridging the gap between technical solutions and user-friendly applications.</p>
            <p>When I'm not immersed in the world of technology, you can find me exploring new creative outlets. I enjoy creating digital art and animations, and you can see some of my work on my Giphy profile. I believe in the power of visual storytelling and strive to combine my technical expertise with creative expression to deliver impactful and engaging experiences.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
