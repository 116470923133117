// src/components/Header.js
import React from 'react';
import './Header.css';
import './About';
import Illustration from './reut1.png'; // Make sure to import your illustration image

const Header = () => {
  return (
    <header className="header">
      <div className="container header-content">


        <div className="header-text">
          <h1>Reouth Rachel Levi</h1>
          <p>Welcome to my personal corner of the internet! I'm Reouth, a passionate creator with a deep love for technology and visual arts. With a background in Computer Science, I've dedicated my career to bridging the gap between technical solutions and user-friendly applications.</p>
          <a href="./about" className="learn-more-btn">Learn more about me</a>
        </div>

        
        <div className="header-image">
          <img src={Illustration} alt="Illustration" />
        </div>
        
      </div>
    </header>
  );
};

export default Header;
