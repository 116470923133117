import React from 'react';

const GiphyGallery = () => {
  // Container style for each GIF (no extra margins)
  const gifContainerStyle = {
    background: '#FFF',
    border: '0',
    borderRadius: '3px',
    boxShadow: '0 0 1px 0 rgba(0,0,0,0.5), 0 1px 10px 0 rgba(0,0,0,0.15)',
    padding: '0',
    width: '100%'
  };

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <h2 style={{ fontFamily: 'sans-serif', fontWeight:'lighter'}}>My Giphy Gallery</h2>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',  // fixed 4 columns
          gap: '10px',
          width: '100%',
          // Calculate container maxWidth: 4 cells * 150px each + 3 gaps * 10px = 630px
          maxWidth: '630px',
          margin: '0 auto'
        }}
      >
        <div style={gifContainerStyle}>
          <iframe src="https://giphy.com/embed/tnNI84i9DXUYqdqp12" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 1"></iframe>
        </div>
        <div style={gifContainerStyle}>
          <iframe src="https://giphy.com/embed/tA5XyhqhzDuk8aoNFF" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 2"></iframe>
        </div>
        <div style={gifContainerStyle}>
          <iframe src="https://giphy.com/embed/op75nrP2YK8mctl4Oj" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 3"></iframe>
        </div>
        <div style={gifContainerStyle}>
          <iframe src="https://giphy.com/embed/qM8kaLlr5pqp7AoVXG" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 4"></iframe>
        </div>
        <div style={gifContainerStyle}>
          <iframe src="https://giphy.com/embed/g4HqxX6Pd127tzfsYi" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 5"></iframe>
        </div>
        <div style={gifContainerStyle}>
          <iframe src="https://giphy.com/embed/jdtfstLIq1T6u6HU3O" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 6"></iframe>
        </div>
        <div style={gifContainerStyle}>
          <iframe src="https://giphy.com/embed/99JupAtp8sNqEFHh9s" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 7"></iframe>
        </div>
        <div style={gifContainerStyle}>
          <iframe src="https://giphy.com/embed/MZdYV0okNh4jbURAcr" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 8"></iframe>
        </div>
        <div style={gifContainerStyle}>
          <iframe src="https://giphy.com/embed/EgHEkxtyCJONa79ea5" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 9"></iframe>
        </div>
        <div style={gifContainerStyle}>
          <iframe src="https://giphy.com/embed/Uxh8u3lEzY2BsDu58n" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 10"></iframe>
        </div>
        <div style={gifContainerStyle}>
          <iframe src="https://giphy.com/embed/Tzstx0wv1Siztoaybi" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 11"></iframe>
        </div>
        <div style={gifContainerStyle}>
          <iframe src="https://giphy.com/embed/Rjc9245TTVAMEIIJLA" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 12"></iframe>
        </div>
        <div style={gifContainerStyle}>
          <iframe src="https://giphy.com/embed/gJoVdEtNGE0OzDOqmY" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 12"></iframe>
        </div>
        <div style={gifContainerStyle}>
          <iframe src="https://giphy.com/embed/RScRn2EbVRLk99bKyG" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 12"></iframe>
        </div>
        <div style={gifContainerStyle}>
          <iframe src="https://giphy.com/embed/g7oZHL4rqrBIGhLc7K" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 12"></iframe>
        </div>
        <div style={gifContainerStyle}>
          <iframe src="https://giphy.com/embed/3Co7Nl78tbdbnEXuRX" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 12"></iframe>
        </div>
        <div style={gifContainerStyle}>
          <iframe src="https://giphy.com/embed/qCz2lRfIlhd3l3ORtY" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 12"></iframe>
        </div>
        <div style={gifContainerStyle}>
          <iframe src="https://giphy.com/embed/Mt8DPnvEmPHWLpgEDn" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 12"></iframe>
        </div>
        <div style={gifContainerStyle}>
          <iframe src="https://giphy.com/embed/HETitWWctlBE25Gf2C" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 12"></iframe>
        </div>
        <div style={gifContainerStyle}>
          <iframe src="https://giphy.com/embed/v2IXmmDl0Of4II02pW" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 12"></iframe>
        </div>
        <div style={gifContainerStyle}>
          <iframe src="https://giphy.com/embed/v5oPIElnbBU3vu1J7H" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 12"></iframe>
        </div>
        <div style={gifContainerStyle}>
          <iframe src="https://giphy.com/embed/jXLeOBI6Wo5Y0YcTcZ" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 12"></iframe>
        </div>
        <div style={gifContainerStyle}>
          <iframe src="https://giphy.com/embed/ARRMZOkzTccXZWTpiA" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 12"></iframe>
        </div>
        <div style={gifContainerStyle}>
          <iframe src="https://giphy.com/embed/9R8mh5Lx6tMseY3f2K" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 12"></iframe>
        </div>
      </div>
    </div>
  );
};

export default GiphyGallery;
