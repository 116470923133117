import React from 'react';

const BlogContainerStyle = {
  background: '#FFF',
  border: '0',
  borderRadius: '3px',
  boxShadow: '0 0 1px 0 rgba(0,0,0,0.5), 0 1px 10px 0 rgba(0,0,0,0.15)',
  padding: '0',
  width: '150px',  // Fixed width
  height: '150px',  // Fixed height
  overflow: 'hidden' , // Ensures nothing spills out
    fontFamily: 'sans-serif',
  fontWeight: 'lighter'
};

const headingStyle = {
  textAlign: 'center',
  padding: '20px',
  fontFamily: 'sans-serif',
  fontWeight: 'lighter'
};

const gridStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gap: '10px',
  width: '100%',
  maxWidth: '630px',
  margin: '0 auto',
    fontFamily: 'sans-serif',
  fontWeight: 'lighter'
};

const imageUrls = [
  "https://lh5.googleusercontent.com/p/AF1QipOs_QvcqMd9GbHXd0ue2ja_9ucIpxg6ScUw04jU=w1200-h1976-p-k-no",
  // Add 11 more URLs for each image
  "https://lh5.googleusercontent.com/p/AF1QipN3ID8kV1_ovpV2LqGzPzTW2sZ0QTkqXZOXbn_3=w1200-h1976-p-k-no",
  "https://lh5.googleusercontent.com/p/AF1QipPp01vRB5EgkkOFKqyujU4bPZC4FfHJbd6BsDZp=w1200-h642-p-k-no",
  "https://lh5.googleusercontent.com/p/AF1QipO4OOcXYBAb_QOpCeZw8mZ8H-aSmRhegGWO1iDk=w1200-h1301-p-k-no",
  "https://lh5.googleusercontent.com/p/AF1QipMOuI9irp05EtUep1vTaZ46kpTQPSdpNXpw8yZs=w1200-h642-p-k-no",
  "https://lh5.googleusercontent.com/p/AF1QipNVMqg3xugAWkgbOvwxsbbDxGRBByQAzX2XXU_h=w1200-h1976-p-k-no",
  "https://lh5.googleusercontent.com/p/AF1QipMC00m2T1nx0VLOqPdwfNzXXPynPXRTQibF37Tu=w1200-h1976-p-k-no",
  "https://lh5.googleusercontent.com/p/AF1QipOUcsJgiVu2vJPATzvO690J7--BGuiRdyTTw7jv=w1200-h642-p-k-no",
  "https://lh5.googleusercontent.com/p/AF1QipNi1bmUM8YRYPPJyrmukbKSLmJhEZywyJHxDvBQ=w1200-h642-p-k-no",
  "https://lh5.googleusercontent.com/p/AF1QipPw-cS4hgi1L7F9Dsms4Yp_MncV8tlwIm8yAXle=w1200-h969-p-k-no",
  "https://lh5.googleusercontent.com/p/AF1QipPfcqqNnaSjPbAhjyTtxCSwEzSWMETq70EnUOKt=w1200-h1976-p-k-no",
  "https://lh5.googleusercontent.com/p/AF1QipPMIt6WegN7lnFCRH1e4k6XBs5-psq-EW-j-t6a=w1200-h642-p-k-no",
];

const Blog = () => {
  return (
    <div style={headingStyle}>
      <h2 style={{fontFamily: 'sans-serif', fontWeight: 'lighter'}}>My Photography</h2>
      <div style={gridStyle}>
        {imageUrls.map((url, index) => (
          <a key={index} href={url} target="_blank" rel="noopener noreferrer" style={{ width: '150px', height: '150px' }}>
            <div style={BlogContainerStyle}>
              <div style={{
                width: '100%',
                height: '100%',
                backgroundImage: `url(${url})`,
                backgroundSize: 'cover',  // Ensures the image covers the div
                backgroundPosition: 'center'  // Centers the image in the div
              }} aria-label={`Photo ${index + 1}`}></div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default Blog;
